import { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import {QRCodeSVG} from 'qrcode.react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faEye, faSignOut } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export const API_URL = 'https://api.tpp.app'
// export const API_URL = 'http://localhost:8000'


function App() {
  
  const storageToken = localStorage.getItem('authToken')
  const [auth, setAuth] = useState()
  const [profile, setProfile] = useState();
  const [profilePhone, setProfilePhone] = useState();
  const [cdr, setCdr] = useState();
  const [vm, setVM] = useState();
  const [vmCount, setVMCount] = useState(0);
  const [vmPage, setVMPage] = useState(0);
  const [vmFilter, setVMFIlter] = useState(false);



  const [copied, setCopied] = useState(false);
  const [cdrPage, setCdrPage] = useState(0);
  const [cdrCount, setCdrCount] = useState(0);
  const [searchCdr, setSearchCdr] = useState('');
  const [cdrDetail, setCdrDetail] = useState('');
  const [cdrFilter, setCdrFilter] = useState(false);
  const [vmReverseCount, setVmReverseCount] = useState(0);
  const [cdrReverseCount, setcdrReverseCount] = useState(0);


  const params = window.location.search;



  const copiedToClipboard = (key) => {
    setCopied(key);
    setTimeout(()=>{
      setCopied('')
    }, 2000)
  }

  const fetchProfile = (token)=>{
    axios.get(API_URL + '/profiles', {
      headers: {
        Authorization: 'JWT ' + token
      }
    }).then(res => {
      setProfile(res.data.results[0])
      axios.get(API_URL + '/i/profiles/'+res.data.results[0].id+'/phone', {
        headers: {
          Authorization: 'JWT ' + token
        }
      }).then(resPhone=>setProfilePhone(resPhone.data.results[0]))
    }).catch(e=>{
      console.log(e)
      localStorage.setItem('authToken', '')
      setAuth('')
    })
  }

  const fetchCDR = (storageToken, page=0, search="", filter=false, action='') =>{
    axios.get(API_URL + `/cdr?limit=10&offset=${page?page*10:'0'}&search=${search}&filter_global=${filter?'1':'0'}`, {
      headers: {
        Authorization: 'JWT ' + storageToken
      }
    }).then(res => {
      if(action==='reverse'){
        setcdrReverseCount(res.data.count)
      } else{
      setCdr(res.data.results)
      setCdrCount(res.data.count)
      fetchCDR(storageToken, 0, "", !filter, 'reverse')
    }
    })
  }

  const fetchVM = (storageToken, page=0, filter=false, action='') =>{
    axios.get(API_URL + `/voicemail?limit=10&offset=${page?page*10:'0'}&filter_global=${filter?'1':'0'}`, {
      headers: {
        Authorization: 'JWT ' + storageToken
      }
    }).then(res => {
      if(action==='reverse'){
        setVmReverseCount(res.data.count)
      } else {
        setVM(res.data.results)
        setVMCount(res.data.count)
        fetchVM(storageToken, 0, !filter, action='reverse')
      }
    })
  }

  const deleteVM = (id) =>{
    axios.patch(
      API_URL + `/voicemail/${id}?&filter_global=${vmFilter?'1':'0'}`,{
        seen: true},{
        headers: {
          Authorization: 'JWT ' + auth
        }
      }
    ).then(() => {
      fetchVM(storageToken, 0, vmFilter)
    })
  }

  const filterCdr = () => {
    setCdrFilter(!cdrFilter)
    setCdrPage(0)
    fetchCDR(storageToken, 0, searchCdr, !cdrFilter)
  }

  const filterVM = () => {
    setVMFIlter(!vmFilter)
    setVMPage(0)
    fetchVM(storageToken, 0, !vmFilter)
  }

  const nextVMPage = () => {
    let add = 1
    if(!vmPage){
      add = 2
    }
    setVMPage(vmPage+add)
    fetchVM(storageToken, vmPage+add, vmFilter)
  }

  const prevVMPage = () => {
    setVMPage(vmPage-1)
    fetchCDR(storageToken, vmPage-1, vmFilter)
  }

  const nextCdrPage = () => {
    let add = 1
    if(!cdrPage){
      add = 2
    }
    setCdrPage(cdrPage+add)
    fetchCDR(storageToken, cdrPage+add, searchCdr, cdrFilter)
  }

  const prevCdrPage = () => {
    setCdrPage(cdrPage-1)
    fetchCDR(storageToken, cdrPage-1, searchCdr, cdrFilter)
  }

  const search = (search) => {
    setSearchCdr(search)
    setCdrPage(0)
    fetchCDR(storageToken, 0, search, cdrFilter)
  }
  useEffect(()=>{
    const checkForToken = () => {
      if(params.includes('token')){
        return params.split('=')[1]
      }
      return ''
    }
    const urlToken = checkForToken();
    if(urlToken && !storageToken){
      window.history.pushState({}, document.title, "/");
      setAuth(urlToken)
      localStorage.setItem('authToken', urlToken)
    }
    const token = localStorage.getItem('authToken')
    if(token && !auth){
      setAuth(token)
      fetchProfile(token)
      fetchCDR(token)
      fetchVM(token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[storageToken, auth, params])


  const onGoogleLogin = (token) => {
    axios.post(API_URL+"/rest-auth/google/", {access_token: token.accessToken}).then(authRes=>{
      setAuth(authRes.data.token)
      localStorage.setItem('authToken', authRes.data.token)
      fetchProfile(authRes.data.token)
      fetchCDR(authRes.data.token)
      fetchVM(authRes.data.token)
      })}

  const getJiraUrl = () => {
    return `https://treeplantingprojects.atlassian.net/servicedesk/customer/portal/3/group/8/create/31?description=Registrierung Telefon ${profile.email}`
  }

  const Player = ({url}) => {
    return (
    <AudioPlayer
      src={url}
      showJumpControls={false}
      autoPlayAfterSrcChange={false}
      customAdditionalControls={[]}
      layout="horizontal-reverse"
    />
  )}
  const logout = () => {
    setAuth('')
    localStorage.setItem('authToken', '')
    setProfile()
  }
  return (
    <div className="p-5 items-center justify-center h-screen">
      <div className='flex justify-center mb-2'>
        <img src='/logo.png' width='50' heigh='50' alt='logo'/>
      </div>
      <div className='flex justify-center mb-2'>
        <p className='text-xl font-bold'>TreePlantingProjects - TELEFON</p>
      </div>
    <div className="p-5 rounded border-2 w-50">
      {auth && profile && (
        <div className=''>
         <p className='text-sm font-bold'>Hallo {profile.first_name} 👋</p>
          <button onClick={logout} className='text-right bg-orange-500 hover:bg-orange-700 text-white py-2 px-4 rounded-full'>Abmelden <FontAwesomeIcon icon={faSignOut}/></button>
        </div>

      )}
    </div>
    {auth && profile && (
    <div className="mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2	">
      <div className='p-5 rounded border-2'>
        <p className='text-lg font-bold mb-5'>Telefon Information</p>
        {profilePhone ? (
          <>
        <ul>
          <li className="flex mb-5 content-center">
            <p className='text-sm font-bold mr-2 mt-1'>Durchwahl: </p>
            <p className={`bg-${profilePhone.extension_active?'green':'red'}-500 hover:bg-${profilePhone.extension_active?'green':'red'}-700 text-white py-1 px-4 rounded-full text-sm`}>{profilePhone.extension}</p>
          </li>
          <li className="flex mb-2">
            <p className='text-sm font-bold mr-2 mt-1'>Hotline Anrufe: </p>
            <p className={`${profilePhone.ivr_active?'bg-green-500':'bg-red-500'} ${profilePhone.ivr_active?'hover:bg-green-700':'hover:bg-red-700'}-700 text-white py-1 px-4 rounded-full text-sm`}>{profilePhone.ivr_active?'AKTIV':'INAKTIV'}</p>
          </li>
        </ul>
        </>
        ):(
          <div>
            <p className='text-sm mb-2'>Es ist kein Telefon für dich registriert.</p>
            <a href={getJiraUrl()} className='bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-full'>Jetzt registrieren</a>
          </div>
        )}
      </div>
      {profile && profilePhone && (
      <div className='p-5 rounded border-2'>
      <p className='text-lg font-bold mb-5'>Login Information</p>
      <div className="grid grid-cols-2 gap-4">
      <div>
        <ul>
          <li className="flex mb-2 content-center">
            <p className='text-sm font-bold mr-2'>Benutzername: </p>
            <p className='text-sm font-mono	'>{profilePhone?.extension}</p>
            <CopyToClipboard text={profilePhone?.extension}
              onCopy={()=>copiedToClipboard('extension')}>
              <p className='text-sm'><button className='ml-2'><FontAwesomeIcon icon={copied==='extension'?faCheck:faCopy}/></button></p>
            </CopyToClipboard>
          </li>
          <li className="flex mb-2">
            <p className='text-sm font-bold mr-2'>Passwort: </p>
            <p className='text-sm font-mono	'>{profilePhone?.password}</p>
            <CopyToClipboard text={profilePhone?.password}
              onCopy={()=>copiedToClipboard('password')}>
              <p className='text-sm'><button className='ml-2'><FontAwesomeIcon icon={copied==='password'?faCheck:faCopy}/></button></p>
            </CopyToClipboard>
          </li>
          <li className="flex mb-2">
            <p className='text-sm font-bold mr-2'>SIP-Server: </p>
            <p className='text-sm font-mono	'>com.tpp.app</p>
            <CopyToClipboard text='com.tpp.app'
              onCopy={()=>copiedToClipboard('domain')}>
              <p className='text-sm'><button className='ml-2'><FontAwesomeIcon icon={copied==='domain'?faCheck:faCopy}/></button></p>
            </CopyToClipboard>
          </li>
        </ul>
        </div>
        <div style={{marginTop: "-3em"}}>
          <QRCodeSVG value={API_URL+"/sip?token="+auth} />
          <p>Oder via URL</p>
          <div className='flex'>
          <input 
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={API_URL+"/sip?token="+auth} disabled />
            <CopyToClipboard text={API_URL+"/sip?token="+auth}
              onCopy={()=>copiedToClipboard('sip_url')}>
              <button className='ml-2'><FontAwesomeIcon icon={copied==='sip_url'?faCheck:faCopy}/></button>
            </CopyToClipboard>
          </div>

        </div>
      </div>
      </div>
      )}
    </div>
    )}
     {auth && profile && cdr && (
      <div className="mt-5 grid grid-cols-1	">
          <div className='p-5 rounded border-2'>
            <p className='text-lg font-bold mb-1'>Anrufbeantworter</p>
            <p className='text-sm  mb-5'>{vmCount} verpasste Anrufe</p>
            {profile.is_staff && (
                <button onClick={filterVM} className={`mb-2 text-sm bg-gray-700 text-white py-2 px-4 rounded-full text-sm`}>{vmFilter?`Meine anzeigen`:`Alle anzeigen`}({vmReverseCount})</button>
              )}
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                  <thead >
                      {vm.map(()=>(
                      <tr class="dark:bg-gray-700 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-3 sm:mb-0 text-white">
                        <th class="p-1 text-left font-normal">Nummer</th>
                        <th class="p-1 text-left font-normal">Datum</th>
                        <th class="p-1 text-left font-normal">Dauer</th>
                        <th class="p-1 text-left font-normal actions h-tpp">Audio</th>
                        <th class="p-1 text-left font-normal actions h-action">Aktionen</th>
                      </tr>
                      ))}
                  </thead>
                  <tbody className='flex-1 sm:flex-none'>
                  {vm.map(_vm=>(
                    <tr class="flex flex-col flex-no wrap sm:table-row mb-3 sm:mb-0">
                      <td class="border-grey-light border hover:bg-gray-100 p-1 truncate">{_vm.caller_id}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1 truncate">{new Date(_vm.date).toLocaleString('de')}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1 truncate">{_vm.duration}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1"><Player url={_vm.file}/></td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1">
                        <button onClick={()=>deleteVM(_vm.id)} className='bg-red-600 py-2 px-4 rounded-full text-white '>Löschen</button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              {vm.length !== 0 && (
              <div className='flex justify-center mt-2'>
                <button disabled={vmPage===0} className={`${vmPage===0?'bg-gray-400':'bg-gray-600'} py-2 px-4 rounded-full text-white`} onClick={prevVMPage}>Zurück</button>
                <p className='bg-gray-600 py-2 px-4 rounded-full text-white ml-2 mr-2 text-lg font-bold'>{vmPage||'1'}</p>
                <button disabled={Math.round(vmCount/10)===vmPage} className={`${Math.round(vmCount/10)===vmPage?'bg-gray-400':'bg-gray-600'} py-2 px-4 rounded-full text-white`} onClick={nextVMPage}>Weiter</button>
              </div>
              )}
          </div>
        </div>
     )}
    {auth && profile && cdr && (
      <div className="mt-5 grid grid-cols-1	">
          <div className='p-5 rounded border-2'>
            <p className='text-lg font-bold mb-1'>Anrufliste</p>
            <p className='text-sm  mb-5'>{cdrCount} Anrufe</p>
            <div className='flex mb-2'>
              <input 
                type="text"
                className="mr-2 w-60 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Suche"
                onChange={e=>search(e.target.value)}
              />
              {profile.is_staff && (
                <button onClick={filterCdr} className={`text-sm bg-gray-700 text-white py-2 px-4 rounded-full text-sm`}>{cdrFilter?"Meine Anrufe anzeigen":"Alle Anrufe anzeigen"}({cdrReverseCount})</button>
              )}
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                  <thead >
                      {cdr.map(()=>(
                      <tr class="dark:bg-gray-700 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-3 sm:mb-0 text-white">
                        <th class="p-1 text-left font-normal">Nummer</th>
                        <th class="p-1 text-left font-normal">Ziel</th>
                        <th class="p-1 text-left font-normal">Datum</th>
                        <th class="p-1 text-left font-normal">Dauer</th>
                        <th class="p-1 text-left font-normal">Status</th>
                        <th class="p-1 text-left font-normal">Aktionen</th>
                      </tr>
                      ))}
                  </thead>
                  <tbody className='flex-1 sm:flex-none'>
                  {cdr.map(_cdr=>(
                    <>
                    <tr class="flex flex-col flex-no wrap sm:table-row mb-3 sm:mb-0">
                      <td class="border-grey-light border hover:bg-gray-100 p-1">{_cdr.dst}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1 truncate">{_cdr.src}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1 truncate">{new Date(_cdr.calldate).toLocaleString('de')}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1 truncate">{_cdr.duration}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1">{_cdr.disposition}</td>
                      <td class="border-grey-light border hover:bg-gray-100 p-1 cursor-pointer" onClick={()=>setCdrDetail(_cdr.id)}>Details <FontAwesomeIcon icon={faEye}/></td>
                    </tr>
                        {cdrDetail===_cdr.id && (
                          <tr className='dark:bg-gray-200 dark:border-gray-200 w-full'>
                            <td className='p-1'>
                              <ul>
                                <li>uniqueid: {_cdr.uniqueid}</li>
                                <li>linkedid: {_cdr.linkedid}</li>
                                <li>sequence: {_cdr.sequence}</li>
                              </ul>
                            </td>
                            <td className='p-1'>
                              <ul>
                                <li>calldate: {_cdr.calldate}</li>
                                <li>timestamp: {_cdr.timestamp}</li>
                                <li>duration: {_cdr.duration}</li>
                                <li>billsec: {_cdr.billsec}</li>
                              </ul>
                            </td>
                            <td className='p-1'>
                            <ul>
                              {/* eslint-disable-next-line no-useless-escape */}
                                <li>clid: {_cdr.clid.replaceAll('/\/','').replaceAll('"','')}</li>
                                <li>src: {_cdr.timestamp}</li>
                                <li>duration: dst: {_cdr.duration}</li>
                              </ul>
                            </td>
                            <td className='p-1'>
                              <ul>
                                <li>disposition: {_cdr.disposition}</li>
                                <li>channel: {_cdr.channel}</li>
                                <li>dstchannel: {_cdr.dstchannel}</li>
                                <li>lastapp: {_cdr.lastapp}</li>
                                <li>lastdata: {_cdr.lastdata}</li>
                                </ul>
                            </td>
                            <td className='p-1'>
                            <ul>
                                <li>did: {_cdr.did}</li>
                                <li>cnum: {_cdr.cnum}</li>
                                <li>outbound_cnum: {_cdr.outbound_cnum}</li>
                              </ul>
                            </td>
                            <td>
                            </td>
                          </tr>
                        )}
                      </>
                  ))}
                  </tbody>
              </table>
              {cdr !== 0 && (
              <div className='flex justify-center mt-2'>
                <button disabled={cdrPage===0} className={`${cdrPage===0?'bg-gray-400':'bg-gray-600'} py-2 px-4 rounded-full text-white`} onClick={prevCdrPage}>Zurück</button>
                <p className='bg-gray-600 py-2 px-4 rounded-full text-white ml-2 mr-2 text-lg font-bold'>{cdrPage||'1'}</p>
                <button disabled={Math.round(cdrCount/10)===cdrPage} className={`${Math.round(cdrCount/10)===cdrPage?'bg-gray-400':'bg-gray-600'} py-2 px-4 rounded-full text-white`} onClick={nextCdrPage}>Weiter</button>
              </div>
              )}
            </div>
          </div>
      </div>
    )}
    {!auth && (
    <div className='flex justify-center'>
      {/* <button className='btn' onClick={()=>login()}>Mit Google anmelden 🚀{' '}</button> */}
      <GoogleLogin
        clientId="517857437085-6hnjreb3rfs0mhtbttefeojtataf1csa.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={onGoogleLogin}
        onFailure={console.log}
      />
    </div>
    )}
    </div>
  );
}

export default App;
